import { useState } from "react";
import Landing from "./components/Landing";
import { loadFull } from "tsparticles";
import Particles from "react-tsparticles";
import Logo from "./assets/Veracious.png";
import favIcon from "./assets/Favorite Icon.svg";
import NewsLetter from "./assets/NewsLetter.svg";
import RedoIcon from "./assets/redo.svg";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import Tools from "./components/Tools";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Link } from "react-router-dom";
import NewsLetterComp from "./components/NewsLetterComp";
import HeaderComp from "./components/HeaderComp";

function App() {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const config = {
    particles: {
      number: {
        value: 400,
        density: {
          enable: true,
          value_area: 1000,
        },
      },
      color: {
        value: "#ffffff",
      },
      shape: {
        type: "circle",
        stroke: {
          width: 0,
          color: "#000000",
        },
        polygon: {
          nb_sides: 5,
        },
        image: {
          src: "img/github.svg",
          width: 100,
          height: 100,
        },
      },
      opacity: {
        value: 1,
        random: true,
        anim: {
          enable: true,
          speed: 1,
          opacity_min: 0,
          sync: false,
        },
      },
      size: {
        value: 1.4,
        random: true,
        anim: {
          enable: false,
          speed: 4,
          size_min: 0.3,
          sync: false,
        },
      },
      line_linked: {
        enable: false,
        distance: 150,
        color: "#ffffff",
        opacity: 0.4,
        width: 1,
      },
      move: {
        enable: true,
        speed: 1,
        direction: "none",
        random: true,
        straight: false,
        out_mode: "out",
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 600,
        },
      },
    },
    interactivity: {
      detect_on: "canvas",
      events: {
        onhover: {
          enable: true,
          mode: "bubble",
        },
        onclick: {
          enable: true,
          mode: "bubble",
        },
        resize: true,
      },
      modes: {
        grab: {
          distance: 400,
          line_linked: {
            opacity: 1,
          },
        },
        bubble: {
          distance: 250,
          size: 0,
          duration: 2,
          opacity: 0,
          speed: 3,
        },
        repulse: {
          distance: 400,
          duration: 0.4,
        },
        push: {
          particles_nb: 4,
        },
        remove: {
          particles_nb: 2,
        },
      },
    },
    retina_detect: true,
  };

  return (
    <Box className="App" sx={{ minHeight: "100vh", background: "#121831", position: "relative" }}>
      <Particles id="tsparticles" init={particlesInit} options={config} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 0 }} />
      <Box >
        <HeaderComp />
        <Routes>
          <Route exact path='/' element={<Landing />} />
          <Route exact path='/products' element={<Tools />} />
          <Route exact path='/news-letter' element={<NewsLetterComp />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
