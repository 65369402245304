import { Box, Button, Typography, styled, Link } from "@mui/material";
import NewsLetterSub from "../assets/NewsLetterSub.svg";
import React from "react";
import Separator from "../assets/Separator.svg";
import { ToolCard } from "./Tools";
import Img1 from "../assets/image 1.png";
import Img2 from "../assets/image 2.png";
import Img3 from "../assets/image 3.png";
import Img4 from "../assets/image 4.png";
import Img5 from "../assets/image 5.png";
import Img6 from "../assets/image 6.png";
import Img7 from "../assets/image 7.png";
import Img8 from "../assets/image 8.png";
import Img9 from "../assets/image 9.png";
import Img10 from "../assets/image 10.png";
import Img11 from "../assets/image 11.png";
import Img12 from "../assets/image 12.png";
import Img13 from "../assets/image 13.png";
import Img14 from "../assets/image 14.png";
import Img15 from "../assets/image 15.png";
import Img16 from "../assets/image 16.png";
import NewsCard from "./NewsCard";

export const Text = styled(Typography)({
  fontFamily: "Raleway",
  fontWeight: 600,
  color: "#FFFFFF",
});
const data = [

  {
    id: 15,
    url: "https://us17.campaign-archive.com/?u=06000c472ad27f1e07041f567&id=a864253e1d",
    img: Img16,
    desc: "AI Showdown: Security Breakthroughs, Creative Revolutions, and Rising Challengers",
    date: "Nov 30, 2024",
  },
  {
    id: 14,
    url: "https://us17.campaign-archive.com/?u=06000c472ad27f1e07041f567&id=77be8f7a0e",
    img: Img15,
    desc: "AI in the Crosshairs: Breakthroughs, Blunders, and the Battle for Trust",
    date: "Nov 16, 2024",
  },
  {
    id: 13,
    url: "https://us17.campaign-archive.com/?u=06000c472ad27f1e07041f567&id=d76432dad7",
    img: Img14,
    desc: "AI’s Expanding Role: From Mental Health Support to Controversial Innovations and Creative Frontiers",
    date: "Nov 02, 2024",
  },
  {
    id: 12,
    url: "https://us17.campaign-archive.com/?u=06000c472ad27f1e07041f567&id=a3102eecff",
    img: Img13,
    desc: "AI’s Global Impact: From Cyber Threats to Continuous Tech Advancements",
    date: "Oct 19, 2024",
  },
  {
    id: 11,
    url: "https://us17.campaign-archive.com/?u=06000c472ad27f1e07041f567&id=539068bb45",
    img: Img12,
    desc: "Alibaba's 100+ Models, Meta's New Smart Glasses, FTC Actions, and Industry Highlights",
    date: "Oct 05, 2024",
  },
  {
    id: 10,
    url: "https://us17.campaign-archive.com/?u=06000c472ad27f1e07041f567&id=42e284d2af",
    img: Img11,
    desc: "From AI Sidekicks to Skipping Bar Lines: Who's Winning the Race for Digital Domination?",
    date: "Sep 14, 2024",
  },
  {
    id: 9,
    url: "https://mailchi.mp/veracious/the-new-frontier-for-ai-ai-agents-14186185",
    img: Img10,
    desc: "CogVideoX Challenges Sora, OpenAI’s Strawberry & AI Taking Over Police work",
    date: "Aug 31, 2024",
  },
  {
    id: 8,
    url: "https://mailchi.mp/veracious/the-new-frontier-for-ai-ai-agents-14185906",
    img: Img9,
    desc: "Flux.1 takes on the Giants, Grok-2 shakes up Image Gen, and Relationships with Chatbots?",
    date: "Aug 17, 2024",
  },
  {
    id: 7,
    url: "https://us17.campaign-archive.com/?u=06000c472ad27f1e07041f567&id=f00e45bc0f",
    img: Img8,
    desc: "Google’s Gemini 1.5 Pro Dominates, Alan Turing's Interactive Display and the EU AI Act in now in Effect",
    date: "Aug 03, 2024",
  },
  {
    id: 6,
    url: "https://us17.campaign-archive.com/?u=06000c472ad27f1e07041f567&id=a6edd75db3",
    img: Img7,
    desc: "AI Innovations Unveiled: From Education to Healthcare and Beyond",
    date: "July 20, 2024",
  },
  {
    id: 5,
    url: "https://mailchi.mp/veracious/the-new-frontier-for-ai-ai-agents-14184747",
    img: Img6,
    desc: "AI Innovations & Controversies: Zoom's transformation, Microsoft unveils AI exploit, Poe & Perplexity under fire and Meta's image dilemma",
    date: "July 06, 2024",
  },
  {
    id: 0,
    url: "https://mailchi.mp/veracious/the-new-frontier-for-ai-ai-agents-14184522",
    img: Img1,
    desc: "OpenAI’s new rival from ex Chief Scientist, Anthropic’s New Claude 3.5 Sonnet Model, Amazon’s Gen Expansion and more…",
    date: "June 22, 2024",
  },
  {
    id: 1,
    url: "https://mailchi.mp/veracious/the-new-frontier-for-ai-ai-agents-14184059",
    img: Img2,
    desc: "Elon Musk’s xAI endeavour, Google’s unstable AI-Search and Llama 3’s crown at stake",
    date: "June 08, 2024",
  },
  {
    id: 2,
    url: "https://mailchi.mp/veracious/the-new-frontier-for-ai-ai-agents-14183735",
    img: Img3,
    desc: "Major AI Product Releases and AI Seoul Summit",
    date: "May 25, 2024",
  },
  {
    id: 3,
    url: "https://mailchi.mp/veracious/the-new-frontier-for-ai-ai-agents-14182795",
    img: Img4,
    desc: "Ethics of Foundation Models and much more",
    date: "May 11, 2024",
  },
  {
    id: 4,
    url: "https://mailchi.mp/9592c370ae6c/the-new-frontier-for-ai-ai-agents",
    img: Img5,
    desc: "The new frontier for AI: AI Agents 🚀",
    date: "April 27, 2024",
  },

];
const NewsLetterComp = () => {
  return (
    <>
      <Box sx={{ px: 3, py:{lg:3}, position: "relative", zIndex: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: {
              xs: 4,
              sm: 0,
            },
          }}
        >
          <Link
            href="https://veracious.us17.list-manage.com/subscribe?u=06000c472ad27f1e07041f567&id=481be45865"
            target="_blank"
          >
            <Button
              to="https://veracious.us17.list-manage.com/subscribe?u=06000c472ad27f1e07041f567&id=481be45865"
              variant="contained"
              sx={{
                background: "#21426B !important",
                textTransform: "none",
                // height:'32px'
                px: "16px",
                py: "8px",
                borderRadius: "6px",
                boxShadow: "0px 40px 40px 0px #00000040",
                '&:hover': {
                  background: "#2e507f !important", // slightly lighter background on hover
                },
              }}
            >
              <img style={{ marginRight: "16px" }} src={NewsLetterSub} alt="" />

              <Text
                sx={{
                  fontSize: "14px",
                  backgroundImage:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.3) 100%)",
                  backgroundClip: "text",
                  // WebkitTextFillColor: "transparent",
                }}
              >
                Subscribe to our Newsletter
              </Text>
            </Button>
          </Link>
        </Box>

        <Box
          sx={{
            pt: 3,
            display: "flex",
            justifyContent: "center",
            gap: {xs:0,sm:1},
            flexDirection: "column",
            alignItems: "center",
            textAlign:"center"
          }}
        >
          <Text sx={{ fontWeight: 400, fontSize: {xs:"15px",sm:"14px"} }}>
            The Bi-weekly AI newsletter. Read by over 100,000
          </Text>
          <Text sx={{ fontWeight: 400, fontSize: {xs:"15px",sm:"14px"}, mt:{xs:1, sm:0} }}>
            Researchers, Students, Enthusiasts and Industry professionals around
            the world.
          </Text>
        </Box>

        <Box sx={{ pt:{xs:1,sm:0},display: "flex", justifyContent: "center" }}>
          <img width="100%" src={Separator} alt="" />
        </Box>

        <Box
          sx={{
            py: {xs:3,sm:1},
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            gap: 4,
            flexWrap: "wrap",
            cursor: "pointer",
          }}
        >
          {data.map((item) => {
            return (
              <NewsCard
                key={item.id}
                url={item.url}
                img={item.img}
                desc={item.desc}
                date={item.date}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default NewsLetterComp;
