import { Box, Link, useMediaQuery } from "@mui/material";
import React from "react";
import { ToolCard } from "./Tools";
import { Text } from "./NewsLetterComp";

const NewsCard = ({ url, img, desc, date }) => {
  const truncateText = (text, maxLength) => {
    if (!text) return ""; // Ensure that text is not null or undefined
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const isSmall = useMediaQuery("(max-width:600px)");
  return (
    <ToolCard
      sx={{
        padding: "10px 11px 10px 11px",
        width: {xs:"258px", sm:"158px", lg:"170px"},
        minHeight: {xs:"350px",sm:"235px", lg:"255px"},
        borderRadius: "12px",
        cursor: "pointer",
        "&:hover": {
          background:
            "linear-gradient(142.21deg, rgba(27, 51, 81, 0.5) 19.63%, rgba(67, 144, 238, 0.5) 83.01%)",
        },
      }}
    >
      <Link href={url} target="_blank" underline="none">
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <img src={img} alt="" />

          <Box sx={{ my: 2, textAlign: "center" }}>
            <Text
              sx={{
                color: "#1ACBAF",
                fontFamily: "Righteous",
                fontWeight: 400,
                fontSize: {xs:"15px", sm:"12px", lg:"13px"},
                textAlign: "center",
              }}
            >
              {isSmall ? desc :truncateText(desc,85)}
            </Text>
          </Box>

          <Box sx={{ marginTop: "auto", textAlign: "right" }}>
            <Text
              sx={{
                fontFamily: "Righteous",
                fontWeight: 400,
                fontSize: "12px",
                backgroundImage:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.4) 100%)",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textAlign: "center",
              }}
            >
              {date}
            </Text>
          </Box>
        </Box>
      </Link>
    </ToolCard>
  );
};

export default NewsCard;
