import React from "react";
import { Box, Button, Stack, Typography, styled, Grid, isSmall, useMediaQuery, useTheme } from "@mui/material";

import homeLogo from "../assets/home logo.png";
import Separator from "../assets/Separator.svg";

function Landing() {
  const isSmall = useMediaQuery("(max-width:600px)");
  const isLarge = useMediaQuery("(min-width:1300px)");

  const getWidth = () => {
    if (isLarge) {
      return '90%';
    } else if (isSmall) {
      return '100%';
    } else {
      return '70%';
    }
  };

  return (
    <Box
      sx={{
        background: "#121831",
        display: "flex",
        flexDirection: "column",
        px:3,
        py: isLarge? 3:0
      }}
    >
      
      <Stack
        minHeight= {{xs:"70vh",sm:"80vh"}}
        alignItems="center"
        justifyContent={{ xs: "space-evenly", md: "space-between" }}
      >
        <Stack alignItems="center" justifyContent="center">
          <Grid container justifyContent="center">
          <Grid item md={6} xs={12} align="center">
      <img style={{ width: getWidth() }} src={homeLogo} alt="" />
    </Grid>
          </Grid>
        </Stack>
        <Stack alignItems="center" justifyContent="center">
          <Typography
            sx={{
              fontFamily: "Righteous",
              color: "#FFFFFFCC",
              fontWeight: 400,
              fontSize: { xs: "16", sm: "24px" },
              textAlign: "center",
            }}
          >
            Next generation of AI tools that give humans Superpowers
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img width="100%" src={Separator} alt="" />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Landing;
